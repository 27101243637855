import React, { useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { useTheme } from '@material-ui/core/styles';
import Container from '../../../components/molecules/Container';
import Grid from '../../../components/molecules/Grid';
import useStyles from './style';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withNamespaces } from 'react-i18next';
import { Hidden, NoSsr } from '@material-ui/core';
import includes from 'lodash/includes';

import QuickLinks from '../Footer/Sections/QuickLinks';
import Newsletter from '../Footer/Sections/Newsletter';
import FollowUs from '../Footer/Sections/FollowUs';
import DownloadApp from '../Footer/Sections/DownloadApp';
import LogoAndCopyright from '../Footer/Sections/LogoAndCopyright';
import * as locators from './locators';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import { LANGUAGE_CODE } from '../../../../constants';
import { get } from 'lodash';
import { evalConditionForInsideChina } from '../../../utils/cookie';
import { getDictionaryText } from '../../../utils/getDictionaryText';
import { breakpoints } from '../../../../styles/themes/vad/constants';
import {
  getComponentName,
  getPageName,
} from '../../../utils/getPageComponentInfo';
import { canUseDOM } from '../../../utils/canUseDOM';

const Footer = props => {
  const { fields } = props;
  const componentName = getComponentName(props);
  const pageName = getPageName(props);
  const language = get(
    props,
    'sitecoreContext.language',
    LANGUAGE_CODE.DEFAULT
  );
  const icpLicenseNumberHeading = getDictionaryText('icpLicenseNumberHeading');
  const templateName = get(props, 'sitecoreContext.route.templateName', '');
  const isDetailPage = includes(templateName, 'Detail Page');

  const {
    quickLinks,
    quickTitle,
    subscribeTitle,
    description,
    socialMediaHeading,
    socialMediaLinks,
    mobileAppLinks,
    mobileAppTitle,
    ctaText,
    logoImage,
    logoLink,
    copyRight,
    icpLicenseNumber,
    secondaryQuickLinks,

    // advanceLogoImage,
  } = fields || '';
  // const theme = useTheme();
  // const matches = useMediaQuery(breakpoints.values.md);
  const matches = useMediaQuery(`(max-width:${breakpoints.values.md}px)`);
  const belowLg = useMediaQuery(`(max-width:${breakpoints.values.lg}px)`);
  const classes = useStyles();

  useEffect(() => {
    if (canUseDOM) {
      const setFooterScrollFX = () => {
        if (typeof window !== 'undefined' && !belowLg) {
          let footerEl = document.querySelector('.main-footer-wrapper .main-footer');
          let footerWrapperEl = document.querySelector('.main-footer-wrapper');
          if (footerEl && footerWrapperEl) {
            footerWrapperEl.style.height = 'auto';
            let footerElHeight = footerEl.offsetHeight;
            if (footerElHeight <= (window.androidHeight || window.innerHeight)) {
              footerWrapperEl.style.height = footerElHeight + 'px';
              footerWrapperEl.classList.add('footer-fx');
            } else {
              footerWrapperEl.classList.remove('footer-fx');
            }
          }
        }
      };
      setFooterScrollFX();
      if (typeof window !== 'undefined') {
        window.addEventListener('resize', setFooterScrollFX);
        return () => window.removeEventListener('resize', setFooterScrollFX);
      }
    }
  }, []);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return fields ? (
    <div className={`main-footer-wrapper ${classes.root}`}>
      <footer
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-hidden={!isHovered}
        className={`main-footer ${classes.rootInner}`}
        data-locator={locators.COMPONENT_NAME}
        role="contentinfo"
      >
        <Container>
          <Grid>
            <Grid container>
              <Grid item container className={classes.upperSection}>
                <Grid item xs={12} lg={4}>
                  <QuickLinks
                    isMobile={matches}
                    quickLinks={quickLinks}
                    quickTitle={quickTitle}
                    componentName={componentName}
                    pageName={pageName}
                  />
                </Grid>
                <Grid
                  item
                  lg={1}
                  implementation="css"
                  mdDown
                  component={Hidden}
                />
                <Grid item xs={12} lg={4}>
                  <Newsletter
                    isMobile={matches}
                    subscribeTitle={subscribeTitle}
                    description={description}
                    ctaText={ctaText}
                    newsletterForm={fields.newsletterForm}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <NoSsr>
                    {!evalConditionForInsideChina(language) && (
                      <FollowUs
                        isMobile={matches}
                        socialMediaHeading={socialMediaHeading}
                        socialMediaLinks={socialMediaLinks}
                        componentName={componentName}
                        pageName={pageName}
                      // advanceLogoImage={advanceLogoImage}
                      />
                    )}
                  </NoSsr>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <DownloadApp
                  isMobile={matches}
                  mobileAppLinks={mobileAppLinks}
                  mobileAppTitle={mobileAppTitle}
                  componentName={componentName}
                  pageName={pageName}
                />
              </Grid>

              <Grid item xs={12}>
                <LogoAndCopyright
                  logoImage={logoImage}
                  logoLink={logoLink}
                  copyRight={copyRight}
                  icpLicenseNumberHeading={icpLicenseNumberHeading}
                  icpLicenseNumber={icpLicenseNumber}
                  language={language}
                  secondaryQuickLinks={secondaryQuickLinks}
                  componentName={componentName}
                  pageName={pageName}
                  isDetailPage={isDetailPage}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </div>
  ) : null;
};

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(Footer))
);
